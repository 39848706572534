<template>
    <section class="portfolio section" id="portfolio">
        <h2 class="section__title">Portofolio</h2>
        <span class="section__subtitle">Most recent work</span>

        <div class="portofolio__container container swiper">
            <div class="swiper-wrapper">
                <!--==================== PORTFOLIO 1 ====================-->
                <div class="portofolio__content grid swiper-slide">
                    <img src="frontend/assets/img/1.jpeg" alt="" class="portofolio__img">

                    <div class="portofolio__data">
                        <h3 class="portofolio__title">Modern Travel Website</h3>
                        <p class="portofolio__description">Website adaptable to all devices, with ui components and
                            animated interactions.
                        </p>
                        <a href="https://tensholiday.com/" class="button button--flex button--small portofolio__button">
                            Demo
                            <i class="uil uil-arrow-right button__icon"></i>
                        </a>
                    </div>
                </div>

                <!--==================== PORTFOLIO 2 ====================-->
                <div class="portofolio__content grid swiper-slide">
                    <img src="frontend/assets/img/2.jpeg" alt="" class="portofolio__img">

                    <div class="portofolio__data">
                        <h3 class="portofolio__title">Modern Pesantren Website</h3>
                        <p class="portofolio__description">Website adaptable to all devices, with ui components and
                            animated interactions.
                        </p>
                        <a href="https://pesantrenpersis27.com/"
                            class="button button--flex button--small portofolio__button">
                            Demo
                            <i class="uil uil-arrow-right button__icon"></i>
                        </a>
                    </div>
                </div>

                <!--==================== PORTFOLIO 3 ====================-->
                <div class="portofolio__content grid swiper-slide">
                    <img src="frontend/assets/img/3.jpeg" alt="" class="portofolio__img">

                    <div class="portofolio__data">
                        <h3 class="portofolio__title">Modern Grafis Website</h3>
                        <p class="portofolio__description">Website adaptable to all devices, with ui components and
                            animated interactions.
                        </p>
                        <a href="https://airygrafis.com/" class="button button--flex button--small portofolio__button">
                            Demo
                            <i class="uil uil-arrow-right button__icon"></i>
                        </a>
                    </div>
                </div>

                <!--==================== PORTFOLIO 4 ====================-->
                <div class="portofolio__content grid swiper-slide">
                    <img src="frontend/assets/img/4.jpeg" alt="" class="portofolio__img">

                    <div class="portofolio__data">
                        <h3 class="portofolio__title">Modern Machine Website</h3>
                        <p class="portofolio__description">Website adaptable to all devices, with ui components and
                            animated interactions.
                        </p>
                        <a href="https://suburbaru.hilfideveloper.com/"
                            class="button button--flex button--small portofolio__button">
                            Demo
                            <i class="uil uil-arrow-right button__icon"></i>
                        </a>
                    </div>
                </div>

                <!--==================== PORTFOLIO 5 ====================-->
                <div class="portofolio__content grid swiper-slide">
                    <img src="frontend/assets/img/5.jpeg" alt="" class="portofolio__img">

                    <div class="portofolio__data">
                        <h3 class="portofolio__title">Modern Schools Website</h3>
                        <p class="portofolio__description">Website adaptable to all devices, with ui components and
                            animated interactions.
                        </p>
                        <a href="https://smkwidyadirgantara.sch.id/"
                            class="button button--flex button--small portofolio__button">
                            Demo
                            <i class="uil uil-arrow-right button__icon"></i>
                        </a>
                    </div>
                </div>

                <!--==================== PORTFOLIO 6 ====================-->
                <div class="portofolio__content grid swiper-slide">
                    <img src="frontend/assets/img/6.jpeg" alt="" class="portofolio__img">

                    <div class="portofolio__data">
                        <h3 class="portofolio__title">Modern Wedding Website</h3>
                        <p class="portofolio__description">Website adaptable to all devices, with ui components and
                            animated interactions.
                        </p>
                        <a href="https://risalahati.id/" class="button button--flex button--small portofolio__button">
                            Demo
                            <i class="uil uil-arrow-right button__icon"></i>
                        </a>
                    </div>
                </div>

                <!--==================== PORTFOLIO 7 ====================-->
                <div class="portofolio__content grid swiper-slide">
                    <img src="frontend/assets/img/7.png" alt="" class="portofolio__img">

                    <div class="portofolio__data">
                        <h3 class="portofolio__title">Sistem Administrasi Anaonline Website</h3>
                        <p class="portofolio__description">Website adaptable to all devices, with ui components and
                            animated interactions.
                        </p>
                        <a href="https://anaonline.id" class="button button--flex button--small portofolio__button">
                            Demo
                            <i class="uil uil-arrow-right button__icon"></i>
                        </a>
                    </div>
                </div>

                <!--==================== PORTFOLIO 8 ====================-->
                <div class="portofolio__content grid swiper-slide">
                    <img src="frontend/assets/img/6.png" alt="" class="portofolio__img">

                    <div class="portofolio__data">
                        <h3 class="portofolio__title">Sistem Administrasi Anisa Online Website</h3>
                        <p class="portofolio__description">Website adaptable to all devices, with ui components and
                            animated interactions.
                        </p>
                        <a href="https://annisaonline.pemudi.persis.or.id/" class="button button--flex button--small portofolio__button">
                            Demo
                            <i class="uil uil-arrow-right button__icon"></i>
                        </a>
                    </div>
                </div>

                <!--==================== PORTFOLIO 9 ====================-->
                <div class="portofolio__content grid swiper-slide">
                    <img src="frontend/assets/img/8.png" alt="" class="portofolio__img">

                    <div class="portofolio__data">
                        <h3 class="portofolio__title">Finance companies that provide financial services</h3>
                        <p class="portofolio__description">Website adaptable to all devices, with ui components and
                            animated interactions.
                        </p>
                        <a href="https://mofi.id/" class="button button--flex button--small portofolio__button">
                            Demo
                            <i class="uil uil-arrow-right button__icon"></i>
                        </a>
                    </div>
                </div>
            </div>
            <!-- add arrows -->
            <div class="swiper-button-next">
                <i class="uil uil-angle-right-b swiper-portofolio-icon"></i>
            </div>
            <div class="swiper-button-prev">
                <i class="uil uil-angle-left-b swiper-portofolio-icon"></i>
            </div>

            <!-- add pagination -->
            <div class="swiper-pagination"></div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Portfolio'
}
</script>