<template>
    <section class="skills section" id="skills">
        <h2 class="section__title">Skills</h2>
        <span class="section__subtitle">My technical level</span>

        <div class="skills_container container grid">
            <div>
                <!--==================== SKILLS 1 ====================-->
                <div class="skills__content skills__open">
                    <div class="skills__header">
                        <i class="uil uil-brackets-curly skills__icon"></i>

                        <div>
                            <h1 class="skills__titles">Frontend developer</h1>
                            <span class="skills__subtitle">More than 2 years</span>
                        </div>

                        <i class="uil uil-angle-down skills__arrow"></i>
                    </div>

                    <div class="skills__list grid">
                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Tailwind</h3>
                                <span class="skills__number">70%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__tailwind"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Bootstrap</h3>
                                <span class="skills__number">90%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__bootstrap"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Next Js</h3>
                                <span class="skills__number">40%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__next"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Nuxt Js</h3>
                                <span class="skills__number">50%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__nuxt"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Angular</h3>
                                <span class="skills__number">30%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__angular"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--==================== SKILLS 2 ====================-->
                <div class="skills__content skills__close">
                    <div class="skills__header">
                        <i class="uil uil-server-network skills__icon"></i>

                        <div>
                            <h1 class="skills__titles">Backend developer</h1>
                            <span class="skills__subtitle">More than 2 years</span>
                        </div>

                        <i class="uil uil-angle-down skills__arrow"></i>
                    </div>

                    <div class="skills__list grid">
                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">PHP</h3>
                                <span class="skills__number">80%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__php"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Nest Js</h3>
                                <span class="skills__number">40%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__nest"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Golang</h3>
                                <span class="skills__number">20%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__golang"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Java</h3>
                                <span class="skills__number">40%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__java"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--==================== SKILLS 3 ====================-->
                <div class="skills__content skills__close">
                    <div class="skills__header">
                        <i class="uil uil-syringe skills__icon"></i>

                        <div>
                            <h1 class="skills__titles">Software Enginer In Test (SDET)</h1>
                            <span class="skills__subtitle">More than 3 years</span>
                        </div>

                        <i class="uil uil-angle-down skills__arrow"></i>
                    </div>

                    <div class="skills__list grid">
                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Playwright Tools</h3>
                                <span class="skills__number">85%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__playwright"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Katalon Tools</h3>
                                <span class="skills__number">85%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__katalon"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Webdriver Io</h3>
                                <span class="skills__number">80%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__webdriver"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Cypress</h3>
                                <span class="skills__number">80%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__cypress"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Selenium</h3>
                                <span class="skills__number">80%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__selenium"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Cucumber, Java, Javascript, Typescript, Functional & Non Functional</h3>
                                <span class="skills__number">75%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__anylanguange"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--==================== SKILLS 4 ====================-->
                <div class="skills__content skills__close">
                    <div class="skills__header">
                        <i class="uil uil-share-alt skills__icon"></i>

                        <div>
                            <h1 class="skills__titles">DevOps</h1>
                            <span class="skills__subtitle">More than 1 years</span>
                        </div>

                        <i class="uil uil-angle-down skills__arrow"></i>
                    </div>

                    <div class="skills__list grid">
                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Jenkins Tools</h3>
                                <span class="skills__number">45%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__jenkins"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Amazon Web Services (AWS)</h3>
                                <span class="skills__number">55%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__aws"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Google Cloud Platform (GCP)</h3>
                                <span class="skills__number">40%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__gcp"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Docker</h3>
                                <span class="skills__number">40%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__docker"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Github, Gitlab, Bitbucket</h3>
                                <span class="skills__number">75%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__anytools"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--==================== SKILLS 5 ====================-->
                <div class="skills__content skills__close">
                    <div class="skills__header">
                        <i class="uil uil-swatchbook skills__icon"></i>

                        <div>
                            <h1 class="skills__titles">Designer</h1>
                            <span class="skills__subtitle">More than 1 years</span>
                        </div>

                        <i class="uil uil-angle-down skills__arrow"></i>
                    </div>

                    <div class="skills__list grid">
                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Figma</h3>
                                <span class="skills__number">80%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__figma"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Adobe XD</h3>
                                <span class="skills__number">30%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__adobe"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Photoshop</h3>
                                <span class="skills__number">85%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__photoshop"></span>
                            </div>
                        </div>

                        <div class="skills__data">
                            <div class="skills__titles">
                                <h3 class="skills_name">Corel Draw</h3>
                                <span class="skills__number">30%</span>
                            </div>
                            <div class="skills__bar">
                                <span class="skills__percentage skills__corel"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Skills'
}

</script>